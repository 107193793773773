// import { defineAsyncComponent } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
// import lazyComponent from 'public/src/services/lazyComponent/lazy_hydrated.js' // 组件懒加载
import StaticImage from './static-image/index.vue'
import { hydrateWhenVisible } from '@shein-aidc/shein-lazy-hydration'

const componentsMap = {
  // 单图、双图、三图
  StaticImage,
  // 轮播图
  SideslipImage: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-store-sideslip-image" */'./sideslip-image/SideslipImage.vue')),
  // 滑动多图组件
  StoreSideslipImage: hydrateWhenVisible(
    () => import(/* webpackChunkName: "bff-store-sideslip-image" */'./sideslip-image/SideslipImage.vue')
  ),
  // 一行五品牌
  BrandRecommend: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-store-brand-recommend" */'./brand-recommend/index')), 
  // 店铺品类推荐
  StoreCategoryDisplayRecommend: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-store-category-display-recommend" */'./store-category-display-recommend/store-category-display-recommend.vue')),
  // 单活动banner 多活动banner
  StorePageSingleActivityBanner: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-store-single-activity-banner" */'./activity-banner/single-activity-banner.vue')),
  // 品牌展示banner
  BrandDisplay: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-store-brand-display" */'./brand-display-comp/index.vue')),
  // 品类展示banner
  CategoryDisplay: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-store-category-display" */'./category-display-comp/index.vue')),
  // 活动小banner
  ActivityBanner: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-store-activity-banner" */'./activity-banner/index.vue')),
  // 店铺装修热销榜
  StoreRankHotSale: hydrateWhenVisible( () => import(/* webpackChunkName: "bff-ccc-store-rank-hot-sale" */'./rank-hot-sale/RankHotSale.vue')), 
  // 店铺装修上新
  StoreNewArrivalList: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-ccc-store-new-arrival-list" */'./new-arrival-list/NewArrivalList.vue')),
  // 店铺关注引导组件
  StoreFollow: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-ccc-store-follow" */'public/src/pages/store_pages/storeBanner/FlowFollow.vue')),
  // 店铺折扣商品组件
  StoreDiscountProductsRecommend: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-ccc-store-discount-products-recommend" */'./store-discount-products-recommend/StoreDiscountProductsRecommend.vue')),
  // 店铺视频组件
  StoreVideo: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-ccc-store-video" */'./store-video/StoreVideo.vue')),
  // 店铺闪购组件
  StoreFlashSale: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-ccc-store-flash-sale" */'./store-flash-sale/StoreFlashSale.vue')),
  // 店铺热搜词
  StoreHotSearchtermRecommend: hydrateWhenVisible(
    () => import(/* webpackChunkName: "bff-ccc-store-hot-searchterm-recommend" */'./store-hot-searchterm-recommend/StoreHotSearchtermRecommend.vue')
  ),
  // 品类品牌单品组件
  CategoryBrandItem: hydrateWhenVisible(
    () => import(/* webpackChunkName: "bff-ccc-store-category-brand-item" */'./category-brand-item/CategoryBrandItem.vue'),
  ),
  // 自选横滑商品
  SelectedHorizontalProductsDynamic: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-ccc-product-items" */'./selected-horizontal-products-dynamic/index.vue')),
  // 品类商品列表
  CategoryItemsDynamic: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-ccc-category-items-dynamic" */'./category-items-dynamic/index.vue')),
  // 店铺单品
  SingleProduct: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-ccc-store-single-product" */'./single-product/SingleProduct.vue')),
  // 店铺lookbook组件
  StoreLookbook: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-ccc-store-lookbook" */'./look-book/index.vue')),
  // 店铺横向列表
  StoreHorizontalItems: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-ccc-store-horizontal-items" */'./horizontal-items/index.vue')),
  // 店铺纵向列表
  StoreVerticalItems: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-ccc-store-vertical-items" */'./vertical-items/index.vue')),
  // 店铺dailynew组件
  StoreDailynew: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-ccc-store-daily-new" */'./daily-new/index.vue')),
  // 搭配展示组件
  CollocationDisplay: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-ccc-store-collocation-display" */'./collocation-display-comp/CollocationDisplay.vue')),
  // 品类热度推荐(店铺)
  StoreCategoryRecommend: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-ccc-store-category-recommend" */'./store-category-recommend/StoreCategoryRecommend.vue')),
  // 推荐商品组件
  ProductRecommend: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-ccc-store-recommend-flow" */'public/src/pages/components/ccc/components/ProductRecommend.vue')),
  // 平台/店铺 横向券
  StoreHorizontalCoupon: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-ccc-store-coupon-list" */'./coupon/index.vue')),
  // 店铺促销活动组件
  ShopActivity: hydrateWhenVisible(() => import(/* webpackChunkName: "bff-ccc-store-shop-activity" */'./shop-activity/ShopActivity.vue')),
}

// const components = Object.entries(componentsMap).reduce((prev, cur) => {
//   prev[`${cur[0]}`] = lazyComponent({
//     componentFactory: cur[1],
//   })
//   if (['HorizontalAnchor', 'HorizontalAnchorImage'].includes(cur[0])) {
//     prev[`${cur[0]}`] = cur[1]
//   }
//   return prev
// }, {})
componentsMap['ClientOnly'] = ClientOnly
// componentsMap['LazyHydrationWrapper'] = LazyHydrationWrapper

export default componentsMap
